import queryString from 'query-string';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { ThunkAction } from 'redux-thunk';

import { setPurchase } from 'core/store/billing/actions';
import { remoteConfigSelector } from 'core/store/remote-config/selectors';
import { REDIRECT_TO_BROWSER, doRedirectToBrowser } from 'core/utils/redirect-to-browser';
import { Purchase } from 'core/interfaces/billing';

import { FlowStep, SUBSCRIPTION_NAMES } from 'src/constants/screens';
import { ROUTES, SUBSCRIPTION } from 'src/constants/routes';

import { processEmail, } from '../profile/actions';
import { RootState } from '../types';

import { selectFlow } from './selectors';

import { routingSlice } from '.';

export const { setCompleted, setStartRoute, setStep } = routingSlice.actions;

export const init = createAsyncThunk(
  `${routingSlice.name}/init`,
  async (_, { dispatch, getState }) => {
    const { redirectToBrowser } = remoteConfigSelector(getState());
    const flow = selectFlow(getState());

    const {
      billing: { purchase },
    } = getState() as RootState;

    if (redirectToBrowser === REDIRECT_TO_BROWSER.ENABLED) {
      doRedirectToBrowser({ mode: redirectToBrowser });
    }

    const params = queryString.parse(window.location.search);

    if (params.purchased !== undefined) {
      dispatch(setPurchase({} as Purchase));
    }

    if (purchase) {
      sessionStorage.setItem('completed', 'true');
      dispatch(setCompleted(true));
    }

    dispatch(setCompleted(params.purchased !== undefined || params.completed !== undefined || sessionStorage.getItem('completed') === 'true'));

    if (params.email) {
      dispatch(processEmail(params.email as string));
    }

    const {
      routing: { isCompleted },
    } = getState() as any;

    const startRoute = params.create_account === null ? ROUTES.CREATE_ACCOUNT : !isCompleted ? ROUTES[flow[0].toUpperCase() as FlowStep] : await dispatch(getRouteAfterFlow());
    dispatch(setStartRoute(startRoute));

    return Promise.resolve();
  });

export const getRouteAfterFlow = (): ThunkAction<string, any, unknown, any> => (dispatch, getState) => {
  const {
    billing: { purchased },
    remoteConfig: { presummaryStack },
  } = getState();
  if (purchased) {
    return SUBSCRIPTION[SUBSCRIPTION_NAMES.SUCCESS];
  } else if (presummaryStack?.length) {
    return SUBSCRIPTION[SUBSCRIPTION_NAMES.PRESUMMARY];
  } else {
    return SUBSCRIPTION[SUBSCRIPTION_NAMES.MAIN];
  }
};