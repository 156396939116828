import { ProgressDescriptionType, type FaceCapture } from '@web-solutions/face-reading/types';

import {
  INITIAL_CONFIG as CORE_INITIAL_CONFIG,
  type LString,
  type RemoteConfig as CoreRemoteConfig,
  type Nullable,
  BaseOfferProductTypes,
} from '@web-solutions/core/constants/remote-config';
import { InterButtonConfig } from '@web-solutions/core/payment/constants';
import type { BaseUpsaleOfferType, BaseReportViewType, BaseUpsalesRemoteContentParams } from '@web-solutions/core/interfaces/upsaleOffers'
import { CoreSummarySection } from '@web-solutions/core/summary/types';

import { AfterPurchaseRoutes, FlowStep } from './screens';

export enum SummarySection {
  HEADER = 'header',
  HEADER_STRESS = 'header_stress',
  FEEDBACK_LIST = 'feedback_list',
  MONEY_BACK = 'money_back',
  MONEY_BACK_POLICY = 'money_back_policy',
  FACE_CHECK = 'face_check',
  FACE_OVERVIEW = 'face_overview',
  LAST_WEEK = 'last_week',
  SPHERES_GRAPH = 'spheres_graph',
  DATA_ANALYSIS = 'data_analysis',
  DOWNLOADED = 'download',
  HOW_IT_WORKS = 'how_it_works',
  SMART_WAY = 'smart_way',
  FAQ = 'faq',
  CAPSULE_TOUR = 'capsule_tour',
  HANDLING_STRESS = 'handling_stress',
  WILL_HELP_YOU = 'will_help_you',
  GOALS = 'goals',
  USERS_JOINED = 'users_joined',
  WE_ANALYZED = 'we_analyzed',
  BIOLOGICAL_AGE = 'biological_age',
  TEXT_HEADER = 'text_header',
  UNLOCK_PRODUCTIVITY = 'unlock_productivity',
  PROCRASTINATION_SUMMARY = 'procrastination_summary',
  APP_BUNDLE_GIFT = 'app_bundle_gift',
  TRAUMA_FEEDBACKS = 'trauma_feedbacks',
  TRAUMA_INTRO = 'trauma_intro',
  FEATURED_IN = 'featured_in',
  TRAUMA_FAQ = 'trauma_faq',
  DISCOUNT_TIMER = 'discount_timer',
  WHAT_YOU_GET = 'what_you_get',
  OVERALL_MOOD = 'overall_mood',
  BEHAVIORAL_SCIENCE = 'behavioral_science',
  PERSONALIZED_PLAN = 'personalized_plan',
  HEALING_PROGRESS = 'healing_progress'
}

export enum QUIZ_WELCOME_CONTENT {
  MENTAL = 'mental',
  TARGET = 'target',
  GIRL = 'girl',
  MAN = 'man',
}

export type SuccessPageType = 'extended_email' | 'extended_email_v2' | 'extended_email_cards' | 'with_changing_email'

export enum OfferProductId {
  MENTAL = 'mental',
  SELF = 'self',
  ALL = 'all',
}

export type ReportViewType = BaseReportViewType | 'ALT_ADHD'

export interface OfferProductTypes extends BaseOfferProductTypes<OfferProductId> {
  materialsAmount: number,
}

export type UpsaleOffer = BaseUpsaleOfferType<Omit<OfferProductTypes, 'id' | 'materialsAmount'>, ReportViewType, { successModalEnabled?: boolean }> & BaseUpsalesRemoteContentParams

export type FinallOfferProduct = Omit<OfferProductTypes, | 'id'>;

export type AfterPurchaseFlow = AfterPurchaseRoutes;

export interface RemoteConfig extends CoreRemoteConfig<FlowStep> {
  redirectToAppFromSuccessTime: number,

  landingStack: Array<SummarySection | CoreSummarySection>,
  landingButtons: Array<InterButtonConfig<SummarySection>>,

  presummaryStack: Array<SummarySection | 'reserved_price'>,
  presummaryButtons: Array<InterButtonConfig<SummarySection>>,

  summaryHeader: {
    title: LString,
    subtitle: LString,
  },

  faceOverview: {
    withMetrics: boolean,
    withBiologicalAge: boolean,
  }
  magicStages: LString[],
  magicDurationLoad: number,
  afterPurchaseFlow: AfterPurchaseFlow,
  autocompleteEmail: boolean,
  focusEmail: boolean,

  isFaceReadingSkipAvailable: boolean,
  isFaceReadingUploadPhotoAvailable: boolean,
  isFaceReadingBackAvailable: boolean,
  isFaceReadingApproveAvailable: boolean,

  pulseMeasurementDuration: number,
  isBlurOnHandlingStress: boolean,

  cameraInitTimeout: number,

  quizWelcomePage: {
    uptitle: LString,
    title: LString,
    subtitle: LString,
    button: LString,
    content: QUIZ_WELCOME_CONTENT,
  },
  emailPageTitle: LString,

  createAccountFlow: Array<'email' | 'apple_id'>,
  successPageType: SuccessPageType,
  successPageWithChangingEmail: {
    withAppPage: boolean
    withVerifyPage: boolean
  }

  appBundleGiftSectionTitle: string,
  toolboxOffer: {
    title: string,
    sub_title: string,
    button: string,
    skip_button: string,
    activeOfferId: OfferProductId | null,
    successModalEnabled: boolean,
    products: OfferProductTypes[],
    compact: boolean,
  },
  toolboxFinalOffer: {
    enabled: boolean,
    button: string,
    skip_button: string,
    product: FinallOfferProduct,
  },
  adhdOffer: UpsaleOffer,
  sleepOffer: UpsaleOffer,
  stressOffer: UpsaleOffer,
  foodOffer: UpsaleOffer,
  faceCapture: FaceCapture,
  faceReadingWelcomeType: 'image' | 'static' | 'video',
  progressDescriptionType: ProgressDescriptionType,
  isAllowSetApplinkToClipboard: boolean,
}

export const INITIAL_CONFIG: RemoteConfig = {
  ...CORE_INITIAL_CONFIG,

  redirectToAppFromSuccessTime: 0,

  landingStack: [],
  landingButtons: [],
  presummaryStack: [],
  presummaryButtons: [],
  summaryHeader: {
    title: '',
    subtitle: '',
  },

  faceOverview: {
    withBiologicalAge: true,
    withMetrics: true,
  },

  flow: [],

  magicStages: [],
  magicDurationLoad: 8000,
  afterPurchaseFlow: ['TOOLBOXES_OFFER'],
  autocompleteEmail: false,
  focusEmail: false,

  isFaceReadingSkipAvailable: true,
  isFaceReadingUploadPhotoAvailable: false,
  isFaceReadingBackAvailable: false,
  isFaceReadingApproveAvailable: false,

  pulseMeasurementDuration: 20,
  isBlurOnHandlingStress: false,

  cameraInitTimeout: 4000,

  quizWelcomePage: {
    uptitle: '',
    title: '',
    subtitle: '',
    button: '',
    content: QUIZ_WELCOME_CONTENT.MENTAL,
  },
  emailPageTitle: { en: '' },

  createAccountFlow: ['email'],
  successPageType: 'extended_email',
  successPageWithChangingEmail: {
    withAppPage: true,
    withVerifyPage: true,
  },

  appBundleGiftSectionTitle: '',
  isOneClickFootnote: false,
  toolboxOffer: {
    title: '',
    sub_title: '',
    button: '',
    skip_button: '',
    activeOfferId: OfferProductId.ALL,
    successModalEnabled: true,
    products: [],
    compact: false,
  },
  toolboxFinalOffer: {
    enabled: true,
    button: '',
    skip_button: '',
    product: {
      title: '',
      subTitle: '',
      notActivePrice: '',
      activePrice: '',
      discount: '',
      currency: 'USD',
      price: 1,
      materialsAmount: 50,
    },
  },
  adhdOffer: {
    title: '',
    text: '',
    button: '',
    product: {
      title: '',
      subTitle: '',
      notActivePrice: '',
      activePrice: '',
      discount: '',
      price: 1,
      currency: 'USD',
    },
    view: "WITH_IMAGE",
  },
  sleepOffer: {
    title: '',
    text: '',
    button: '',
    product: {
      title: '',
      subTitle: '',
      notActivePrice: '',
      activePrice: '',
      discount: '',
      price: 1,
      currency: 'USD',
    },
    view: "WITH_IMAGE",
  },
  stressOffer: {
    title: '',
    text: '',
    button: '',
    product: {
      title: '',
      subTitle: '',
      notActivePrice: '',
      activePrice: '',
      discount: '',
      price: 1,
      currency: 'USD',
    },
    view: "WITH_IMAGE",
  },
  foodOffer: {
    title: '',
    text: '',
    button: '',
    product: {
      title: '',
      subTitle: '',
      notActivePrice: '',
      activePrice: '',
      discount: '',
      price: 1,
      currency: 'USD',
    },
    view: "NO_IMAGE",
  },
  faceCapture: {
    stressScanEnabled: true,
    isAutoTakeAfterScan: true,
    withHeartRateLine: true,
    duration: 15000,
    mode: 'basic',
    faceLib: 'mediapipe',
  },
  faceReadingWelcomeType: 'static',
  progressDescriptionType: ProgressDescriptionType.DEFAULT,
  isAllowSetApplinkToClipboard: false,
};

export type FullConfig = Nullable<Partial<RemoteConfig>>;
